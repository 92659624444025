<template>
	<w-dialog :loading="dataLoading" no-padding :title="dialogTitle" :value="dialog" width="500px" @close="closeDialog">
		<v-layout 
			column 
			:style="{
				'min-height': dataLoading ? '200px' : 'inherit',
				position: 'relative'
			}"
			>
			<v-layout
				v-if="dataLoading"
				align-center
				class="w-loading-container"
				justify-center
				style="height: 100%; min-height: 200px; position: absolute; width: 100%; z-index: 1"
			>
				<v-progress-circular color="primary" indeterminate />
			</v-layout>
			<v-layout column pa-3>
				<SubscriptionForm ref="form" v-model="subscriptionParams" :params-af="paramsAf" :validation.sync="validation" @submit="save" />
			</v-layout>
		</v-layout>
		<template v-if="!dataLoading" v-slot:actions>
			<v-spacer />
			<w-btn-save :disabled="!(validation && wasModified)" :loading="loading" @click="save" />
		</template>
	</w-dialog>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'SubscriptionDialog',
	components: {
		SubscriptionForm: () => ({
			component: import('@/components/Applications/SubscriptionForm')
		})
	},
	mixins: [ApplicationsModuleGuard],
	props: {
		application: {
			default: null,
			required: false,
			type: Object
		},
		value: {
			default: false,
			required: false,
			type: Boolean
		}
	},
	data: function () {
		return {
			dataLoading: false,
			loading: false,
			originalParams: {},
			subscriptionParams: {},
			validation: false,
			paramsAf: [],
		}
	},
	computed: {
		dialog: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		dialogTitle: function () {
			const application = this.application ? this.application.title : ''
			let result = this.$t('application.subscription_title', { application })
			if (this.application && this.application.catalog_application_id) {
				result = this.$t('application.set_up_application', { application })
			}
			return result
		},
		wasModified: function () {
			if (!this.application || !this.application.catalog_application_id) {
				return true
			}

			return JSON.stringify(this.originalParams) != JSON.stringify(this.subscriptionParams)
		}
	},
	watch: {
		value: {
			handler: 'onDialogOpened'
		}
	},
	methods: {
		closeDialog: function () {
			this.dialog = false
			this.subscriptionParams = {}
			if (this.$refs && this.$refs.form) {
				this.$refs.form.reset()
			}
		},
		createSubscription: function () {
			return this.service.createSubscription(this.accountingFirmId, this.application.id, this.subscriptionParams).then(result => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('application.actions.created', { name: this.application.title }))
				this.eventBus.emit(this.events.APPLICATION_SUBSCRIPTION_CREATED, result)
			}).catch(error =>  {
				if(error?.name == 'MissingInformationException') {
					this.paramsAf = error.parameters
				}
				throw error
			})
		},
		loadParams: function () {
			if (!this.application?.id) {
				this.applicationFields.splice(0, this.applicationFields)
				return Promise.resolve()
			}

			return this.service.getApplicationSubscription(this.accountingFirmId, this.application.id).then(subscription => {
				this.subscriptionParams = subscription
				if (subscription.params) {
					Object.assign(this.subscriptionParams, subscription.params)
					delete this.subscriptionParams.params
				}
				this.originalParams = JSON.parse(JSON.stringify(this.subscriptionParams))
				this.$refs.form.resetValidation()
			})
		},
		modifySubscription: function () {
			const requiredNames = this.paramsAf.map(param => param.name);
			const filteredParams = Object.keys(this.subscriptionParams)
  				.filter(key => requiredNames.includes(key))
  				.reduce((obj, key) => {
    				obj[key] = this.subscriptionParams[key];
    				return obj;
  				}, {});
			return this.service.updateAccountingFirmApplication(this.accountingFirmId, this.application.id, {params: filteredParams}).then(result => {
				this.eventBus.emit(this.events.APPLICATION_SUBSCRIPTION_UPDATED, result)
			})
		},
		onDialogOpened: function () {
			this.paramsAf = this.application ? this.application.params_accounting_firm : []
			if (!this.dialog || !this.application || !this.application.catalog_application_id) {
				return
			}

			this.dataLoading = true
			this.loadParams().finally(() => {
				this.dataLoading = false
			})
		},
		save: function () {
			if (!this.validation || !this.wasModified) {
				return
			}
			this.loading = true
			let promise = Promise.resolve()
			if (this.application.catalog_application_id) {
				promise = this.modifySubscription()
			} else {
				promise = this.createSubscription()
			}
			promise
				.then(() => {
					this.closeDialog()
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>